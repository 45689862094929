import React from 'react';

import SEO from '../components/seo';

const Home = () => (
	<section className="home">
		<SEO
			title="Accueil"
			description="Concilio offre conseil, accompagnement et réalisation de projets innovants dans le domaine culturel et événementiel, ainsi qu’au sein des hautes écoles."
		/>
		<div className="logo">
			<img src="/logo.svg" alt="Concilio" style={{ width: '20vw' }} />
		</div>
		<p>Concilio offre conseil, accompagnement et réalisation de projets innovants dans le domaine culturel et événementiel, ainsi qu’au sein des hautes écoles.</p>
	</section>
);

export default Home;
